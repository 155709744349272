// --- Variables End ---


// TEXT DEFAULT
@mixin text-default() {
  font-size: $default-font-size;
  color: $paragraph-color;
  font-weight: $font-weight-normal;
}

// INPUT DEFAULT
@mixin input-default($color: $input-font-color, $font: $primary-font, $size: $label-font-size, $background: $input-background-color, $weight: $input-font-weight, $border: 0) {
  color: $color;
  font-family: $font;
  background: $background;
  font-weight: $weight;
  font-size: $size;
  border: $border;
}

// LABEL DEFAULT
@mixin label-default($color: $label-font-color, $font: $primary-font, $size: $label-font-size, $weight: $label-font-weight, $line-height: 1.2) {
  color: $color;
  font-family: $font;
  font-weight: $weight;
  font-size: $size;
  line-height: $line-height;
}

// HEADERS
@function headings($from:1, $to:6) {
  @if $from == $to {
    @return 'h#{$from}';
  } @else {
    @return 'h#{$from},' + headings($from+1, $to);
  }
}

// BREAK POINTS
@mixin maxWidth($screen) {
  @media all and (max-width: ($screen)) {
    @content;
  }
}

@mixin minWidth($screen) {
  @media all and (min-width: ($screen)) {
    @content;
  }
}


// ANIMATION
@mixin animation($delay, $duration, $animation) {
  -webkit-animation-delay: $delay;
  -webkit-animation-duration: $duration;
  -webkit-animation-name: $animation;
  -webkit-animation-fill-mode: forwards;

  -moz-animation-delay: $delay;
  -moz-animation-duration: $duration;
  -moz-animation-name: $animation;
  -moz-animation-fill-mode: forwards;

  -o-animation-delay: $delay;
  -o-animation-duration: $duration;
  -o-animation-name: $animation;
  -o-animation-fill-mode: forwards;

  animation-delay: $delay;
  animation-duration: $duration;
  animation-name: $animation;
  animation-fill-mode: forwards;
}

// ANIMATION KEYFRAMES
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

// TRANSITIONS
@mixin transition($args) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

// TRANSFORM
@mixin transform($args) {
  -webkit-transform: $args;
  -moz-transform: $args;
  -ms-transform: $args;
  -o-transform: $args;
  transform: $args;
}

// TRANSLATION
@mixin translate($pos) {
  -webkit-transform: translate($pos);
  -moz-transform: translate($pos);
  -ms-transform: translate($pos);
  -o-transform: translate($pos);
  transform: translate($pos);
}

// BORDER RADIUS
@mixin border-radius($deg) {
  -webkit-border-radius: $deg;
  -moz-border-radius: $deg;
  border-radius: $deg;
}


// Function for converting a px based font-size to rem.
@function calculateRem($size) {
  $remSize: calc($size / 16);
  //Default font size on html element is 100%, equivalent to 16px;
  @return #{$remSize}rem;
}

// Function for calculating negative magrine.
@function calculateMargin($elementWidth) {
  $margin: calc($elementWidth / 2);
  @return -#{$margin};
}

@function calculateMarginTop($elementHeight) {
  $margin: calc($elementHeight / 2);
  @return -#{$margin};
}

// Mixin that will include the fall back px declaration as well as the calculated rem value.
@mixin fontSize($size) {
  font-size: calculateRem($size);
}

@mixin marginNegative($element) {
  margin-left: calculateMargin($element);
}

@mixin marginNegativeVertical($element) {
  margin-top: calculateMarginTop($element);
}

//calc + vh to allow text to be fluid between minimum and maximum breakpoints.
@mixin fluid-type($min-font-size: 12px, $max-font-size: 21px, $lower-range: 420px, $upper-range: 1024px) {

  font-size: calc(#{$min-font-size} + #{(($max-font-size / ($max-font-size * 0 + 1)) - ($min-font-size / ($min-font-size * 0 + 1)))} * ((100vw - #{$lower-range}) / #{(($upper-range / ($upper-range * 0 + 1)) - ($lower-range / ($lower-range * 0 + 1)))}));

  @media screen and (max-width: $lower-range) {
    font-size: $min-font-size;
  }
  @media screen and (min-width: $upper-range) {
    font-size: $max-font-size;
  }
}

// Gradient animation
@mixin gradientAnimation( $start, $end, $transTime ) {
  background-size: 100%;
  background-image: linear-gradient(90deg, $start, $end);
  position: relative;
  z-index: 9;

  &:before {
    background-image: linear-gradient(90deg, $end, $start);
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    z-index: -9;
    border-radius: 24px;
    transition: opacity $transTime;
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }
}

@mixin gradientAnimationThreePoint( $deg, $start, $middle, $end, $transTime ) {
  background-size: 100%;
  background-image: linear-gradient($deg, $start 0%, $middle 57%, $end 100%);
  position: relative;
  z-index: 9;

  &:before {
    background-image: linear-gradient($deg, $end 0%, $middle 57%, $start 100%);
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    z-index: -9;
    transition: opacity $transTime;
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }
}
