// | Heading 1 <-->
$h1-font-family: $headings-font; /* Font family */
$h1-font-style: normal; /* Font style */
$h1-font-max-size: 65px; /* Max font size */
$h1-font-min-size: 45px; /* Min font size */
$h1-font-weight: $headings-font-weight; /* Font weight */
$h1-margin-bottom: 35px; /* Margin bottom */
$h1-queryMinWidth: 480px; /* Query min width */
$h1-queryMaxWidth: 1600px; /* Query max width */

// | Heading 2 <-->
$h2-font-family: $headings-font; /* Font family */
$h2-font-style: normal; /* Font style */
$h2-font-max-size: 30px; /* Max font size */
$h2-font-min-size: 22px; /* Min font size */
$h2-font-weight: $headings-font-weight; /* Font weight */
$h2-margin-bottom: 25px; /* Margin bottom */
$h2-queryMinWidth: 768px; /* Query min width */
$h2-queryMaxWidth: 1300px; /* Query max width */

// | Heading 3 <-->
$h3-font-family: $headings-font; /* Font family */
$h3-font-style: normal; /* Font style */
$h3-font-max-size: 24px; /* Max font size */
$h3-font-min-size: 18px; /* Min font size */
$h3-font-weight: $headings-font-weight; /* Font weight */
$h3-margin-bottom: 25px; /* Margin bottom */
$h3-queryMinWidth: 480px; /* Query min width */
$h3-queryMaxWidth: 1300px; /* Query max width */

// | Heading 4 <-->
$h4-font-family: $headings-font; /* Font family */
$h4-font-style: normal; /* Font style */
$h4-font-max-size: 18px; /* Max font size */
$h4-font-min-size: 16px; /* Min font size */
$h4-font-weight: $headings-font-weight; /* Font weight */
$h4-margin-bottom: 25px; /* Margin bottom */
$h4-queryMinWidth: 1024px; /* Query min width */
$h4-queryMaxWidth: 1024px; /* Query max width */


// | Heading 5 <-->
$h5-font-family: $headings-font; /* Font family */
$h5-font-style: normal; /* Font style */
$h5-font-max-size: 15px; /* Max font size */
$h5-font-min-size: 15px; /* Min font size */
$h5-font-weight: 25px; /* Font weight */
$h5-margin-bottom: 25px; /* Margin bottom */
$h5-queryMinWidth: 1024px; /* Query min width */
$h5-queryMaxWidth: 1024px; /* Query max width */

// | Heading 6 <-->
$h6-font-family: $headings-font; /* Font family */
$h6-font-style: normal; /* Font style */
$h6-font-max-size: 16px; /* Max font size */
$h6-font-min-size: 16px; /* Min font size */
$h6-font-weight: $headings-font-weight; /* Font weight */
$h6-margin-bottom: 15px; /* Margin bottom */
$h6-queryMinWidth: 1024px; /* Query min width */
$h6-queryMaxWidth: 1024px; /* Query max width */

// | Paragraph <-->
$paragraph-color: #020202; /* Color */
$paragraph-line-height: 1.57; /* Line height */
$paragraph-margin: 30px; /* Margin bottom */
$paragraph-max-width: 900px; /* Max width */
// --- Variables End ---



h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    color: $headings-color;
    line-height: 1.2;
}

h1 {
    font-family: $h1-font-family;
    font-style: $h1-font-style;
    font-weight: $h1-font-weight;
    //@include fluid-type($h1-font-min-size, $h1-font-max-size, $h1-queryMinWidth, $h1-queryMaxWidth);
    margin-bottom: $h1-margin-bottom;
}

h2 {
    font-family: $h2-font-family;
    font-style: $h2-font-style;
    font-weight: $h2-font-weight;
    //@include fluid-type($h2-font-min-size, $h2-font-max-size, $h2-queryMinWidth, $h2-queryMaxWidth);
    margin-bottom: $h2-margin-bottom;
}

h3 {
    font-family: $h3-font-family;
    font-style: $h3-font-style;
    font-weight: $h3-font-weight;
    //@include fluid-type($h3-font-min-size, $h3-font-max-size, $h3-queryMinWidth, $h3-queryMaxWidth);
    margin-bottom: $h3-margin-bottom;
}

h4 {
    font-family: $h4-font-family;
    font-style: $h4-font-style;
    font-weight: $h4-font-weight;
    //@include fluid-type($h4-font-min-size, $h4-font-max-size, $h4-queryMinWidth, $h4-queryMaxWidth);
    margin-bottom: $h4-margin-bottom;
}

h5 {
    font-family: $h5-font-family;
    font-style: $h5-font-style;
    font-weight: $h5-font-weight;
    //@include fluid-type($h5-font-min-size, $h5-font-max-size, $h5-queryMinWidth, $h5-queryMaxWidth);
    margin-bottom: $h5-margin-bottom;
}

h6 {
    font-family: $h6-font-family;
    font-style: $h6-font-style;
    font-weight: $h6-font-weight;
    //@include fluid-type($h6-font-min-size, $h6-font-max-size, $h6-queryMinWidth, $h6-queryMaxWidth);
    margin-bottom: $h6-margin-bottom;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
}

p {
    @include text-default();
    line-height: $paragraph-line-height;
    margin: 0 0 $paragraph-margin 0;
    max-width: $paragraph-max-width;
    font-family: $primary-font;

    a {
        color: $primary-color;
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;

        &:hover {
            color: $paragraph-color;
        }
    }
}

b {
    font-weight: $font-weight-bold;
}

a {
    font-size: $default-font-size;
    font-weight: $font-weight-normal;
    color: $primary-color;
    text-decoration: none;

    &:hover {
        color: $paragraph-color;
    }
}
