@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap');

// | Fonts <-->
$font-weight-normal: 400; /* Default font weight */
$font-weight-bold: 700; /* Bold font weight */
$default-font-size: 15px; /* Default font size */
$default-line-height: 1.2; /* Default line height */
$primary-font: 'Inter', sans-serif; /* Primary font */
$primary-font-weight: $font-weight-normal; /* Primary font weight */
$headings-font: 'Bai Jamjuree', sans-serif; /* Heading font */
$headings-font-weight: 300; /* Heading font weight */
$headings-color: #000; /* Heading font color */
$subHeadings-font: 'Bai Jamjuree', sans-serif; /* Subheading font */
$subHeadings-font-weight: 300; /* Subheading font weight */
$subHeadings-color: #000; /* Subheading font color */
$icon-font-family: 'iconfont'; /* Icon font */
$heading-icon-font-size: 32px; /* Heading icon font size */

$primary-color: #FF4C00;

// --- Variables End ---
