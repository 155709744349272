body {
    background: #E6E6E6;

    > iframe {
        display: none !important;
    }
}

.dashboard-page {
    padding-top: 205px;

    @include maxWidth(680px) {
        padding-top: 140px;
    }
}

.content-wrapper {
    flex: 1;
    display: flex;
    flex-direction: row;
    max-width: 100%;
    background: url('../images/content-bg.png') no-repeat center top;
    //padding: 0 0 80px;

    .main-content-wrapper {
        background: transparent;
    }
}

.pim-header {
    width: 100%;
    background: url('../images/header-bg.jpg') no-repeat center top;
    padding: 20px 20px 120px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;

    @include maxWidth(680px) {
        padding: 20px 0 60px;
    }

    header {
        max-width: 80%;
        border-radius: 80px;
        margin: 0 auto;
        overflow: hidden;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;

        @include maxWidth(680px) {
            max-width: 95%;
        }
    }

    .welcome-user {
        @include maxWidth(540px) {
           display: none;
        }
    }
}

.sidebar-nav {

    .MuiPaper-root {
        box-shadow: none !important;
        top: 150px;
        left: 40px;
        border-radius: 6px;

        @include maxWidth(680px) {
            top: 100px;
            width:350px !important;
        }
    }

    a {
        &.active {
            position: relative;

            &:before {
                display: block;
                content: '';
                width: 40px;
                height: 40px;
                background-color: $primary-color;
                position: absolute;
                left: 22px;
                top: 7px;
                border-radius: 50%;
                z-index: 1;
            }

            svg {
                color: #fff;
                position: relative;
                z-index: 2;
                opacity: 1;
            }

            span {
                color: $primary-color;
            }
        }


    }

    .MuiCollapse-wrapperInner {
        a {
            &.active {
                &:before {
                    display: none;
                }
            }
        }
    }

    span {
        font-size: 20px;
        font-weight: 600;
    }

    span {
        font-size: 20px;
        font-weight: 600;
    }

    .MuiCollapse-root {
        span {
            font-size: 16px;
            font-weight: 400;
        }
    }
}

header {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05) !important;
}

.scrollbar-container {
    border-right: none !important;
}

.full-width {
    width: 100%;
}

.mb-5 {
    margin-bottom: 5px;
}

.toolbar-wrapper {
    background-color: rgba(237, 237, 237, 0.35);
}

.search-bar {
    background: transparent;
}

.search-wrapper {
    > div {
        margin-left: 0 !important;
    }

    .search-icon {
        z-index: 2;
    }
}

.filters-wrapper {
    background-color: transparent;
}

.filter-btn {
    padding: 14px 20px;
}

.shadow {
    -webkit-box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
}

.media-item-accordion {
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1) !important;
    -moz-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1) !important;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1) !important;
    margin-bottom: 4px;
}

.media-item-content {
    background-color: rgba(237, 237, 237, 0.35);
    padding: 25px 20px;
}

.ReactModalPortal {
    position: relative;
    z-index: 9999;
}

.scrollable-content {
    overflow-y: auto;
    height: calc(100vh - 335px);

    &.no-tabs {
        height: calc(100vh - 285px);
    }

}

.MuiPaper-root.MuiPopover-paper, .MuiAutocomplete-popper {
    box-shadow: 1px 1px 4px 1px rgb(0 0 0 / 10%) !important;
    max-height: 400px;
}

.MuiChip-filled {
    height: 27px;
}

.small-cell {
    width: 60px
}

.tabs-wrapper {
    display: inline-grid;
}

.last-th {
    width: 110px;
}

.click-row {
    cursor: pointer;
}

.multi-btn, .margin-right-btn {
    margin-right: 10px;
}

.filters-wrapper.MuiPaper-elevation {
    box-shadow: none !important;

    &.Mui-expanded {
        padding-bottom: 20px;
    }
}

.lang-nav {
    margin-top: 2px !important;
    border: 1px solid #c8c8c8 !important;
    margin-left: 8px;

    &.active {
        background: #fff !important;
        border: 1px solid #fff !important;
    }

    .MuiChip-avatar {
        width: 16px !important;
        height: 16px !important;
    }
}

.read-only {
    .quill {
        opacity: 0.6;
        cursor: inherit;
    }
}

.ril-image-current {
    background: #fff;
}

.preloader {
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 10000;
    top: 0;

    > span {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 20;
    }

    &:before {
        content: "";
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0.1;
        z-index: 10;
        position: absolute;
        top: 0;
    }

}

.loader-message {
    position: fixed;
    top: 62%;
    left: 50%;
}

.clear-check {
    margin-bottom: -25px;
}

.clear-check-top {
    margin-top: -20px;
}

.required-field {
    fieldset {
        border-color: #FF4C00;
        border-width: 2px
    }
}

//MEDIA CENTER
.file-show {
    padding: 20px 10px;

    .file-more {
        margin-top: 30px;

        th {
            padding-left: 0;
        }
    }
}

.browse-media-btn {
    margin-left: 10px;
}

//DASHBOARD
.dashboard-links {
    margin-top: 60px;
    background: transparent;

    .dashboard-card {
        text-align: center;
        padding: 30px 0 10px;
        border-radius: 6px;
        background: #fff;
        @include transition(all 0.25s);

        .img-wrapper {
            margin-bottom: 18px;
        }

        &:hover {
            background: url(../images/card-bg.png) no-repeat center/cover;

            h4 {
                &:after {
                    opacity: 1;
                }
            }
        }
    }

    h4 {
        margin-bottom: 0;
        font-weight: 600;
        position: relative;

        &:after {
            content: '';
            display: block;
            height: 6px;
            width: 120px;
            background-color: $primary-color;
            position: absolute;
            bottom: -34px;
            left: 50%;
            margin-left: -60px;
            opacity: 0;
            @include transition(all 0.25s);
        }

        a {
            color: inherit;
            text-decoration: none;
            @include transition(all 0.3s);

            &:hover {
                color: $primary-color;
                @include transition(all 0.3s);
            }
        }
    }
}

#options-menu {
    li {
        color: #374151;

        svg {
            color: rgba(0, 0, 0, 0.6);
            margin-right: 10px;
        }
    }
}

.related-item {
    max-width: 100%;
    display: block !important;

    .item {
        margin: 0 4px 4px 0;
    }
}

// TABLE
.list-table {

    tr {
        th {
            font-family: $primary-font;
            font-weight: 600;
            color: #000;
            font-size: 14px;
            position: relative;
            padding: 16px 16px;

            &:first-child, &:last-child {
                &:before {
                    display: none;
                }
            }

            &.first-header-cell, &.last-th {
                &:before {
                    display: none;
                }
            }

            &.first-header-cell {
                padding-right: 15px;
                padding-left: 5px;

                + th {
                    &:before {
                        background-color: #ADADAD;
                        width: 1px;
                        height: 30px;
                        margin-top: -15px;
                    }
                }
            }

            &:before {
                display: block;
                content: '';
                width: 3px;
                height: 20px;
                background-color: $primary-color;
                position: absolute;
                left: 0;
                top: 50%;
                margin-top: -10px;
                border-radius: 4px;
            }
        }

        td {
            font-family: $primary-font;
            position: relative;

            &.last-td {
                min-width: 120px;

                &:before {
                    display: none;
                }



            }

            &:before {
                display: block;
                content: '';
                background-color: #DCDCDC;
                position: absolute;
                left: 0;
                top: 50%;
                width: 1px;
                height: 20px;
                margin-top: -10px;
            }

            button {
                &:hover {
                    background-color: $primary-color;
                    color: #fff;
                }
            }
        }
    }
    .spacer {
        display: inline-block;
        vertical-align: middle;
        background-color: #DCDCDC;
        width: 1px;
        height: 20px;
        margin: 0 7px;
    }
}

// TABS
.MuiTabs-scroller {
    button {
        font-weight: 600;
        color: #000;
        font-size: 14px;
    }
}

.MuiOutlinedInput-root {
    border-radius: 6px;
}

.MuiTablePagination-root {
    p {
        margin: 0;
    }
}


::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-button {
    background: #e3e3e3;
    display: none;
}

::-webkit-scrollbar-thumb {
    background: #233044;
    border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
    background: #233044;
}

::-webkit-scrollbar-track {
    background: #e3e3e3;
}

::-webkit-scrollbar-corner {
    background: #ddd;
    border-radius: 5px;
}
